import { useStore } from 'vuex';
// import { v3 as uuidv3 } from 'vue3-uuid'
// import { useRouter } from "vue-router";

import axios from 'axios';
// import date from '../helpers/date'

export default () => {
  const store = useStore();
  // const { uuid } = require('uuidv4');

  const generateUUID = () => { // Public Domain/MIT
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
        d += performance.now(); //use high-precision timer if available
    }
    var newGuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    
    return newGuid;
}
  const optimizeRoute = async (imo, payload) => {
    let request = {    
      "messageId": generateUUID(), 
      "datetime": new Date().toUTCString(),
      "imo": imo, 
      "eventType": "OptimizeRoute", 
      "payload": payload    
  }
  console.log("request",request)
  // return request;
    return axios.post('/api/gateway', request).then(({ data }) => {
      console.log("post('/gateway', request)",data);
      return data;
    });

  };

  const cancelOptimizingRoute = (imo) => {
    let request = {    
      "messageId": generateUUID(), 
      "datetime": new Date().toUTCString(),
      "imo": imo, 
      "eventType": "OptimizeRoute", 
      "payload": null    
  }

  console.log("request",request)
  // return request;
    return axios.post('/api/cancel_task', request).then(({ data }) => {
      console.log("post('/cancel_task', request)",data);
      return data;
    });
  };

  const getLastOptimized = (imo) => {
    return axios.get('/api/optimizationresult/imo/'+ imo +'/last').then(({ data }) => {
      console.log(data);
      // store.commit('routeOptimization/setDetails', data);


      return data;
    });
  };

  
  const getLastLoadedRoute = (imo) => {
    return axios.get('/api/route/imo/'+ imo +'/last').then(({ data }) => {
      console.log(data);
      // store.commit('routeOptimization/setDetails', data);
      return data;
    });
  };

  
  const getWeatherDate = () => {
    return axios.get('/api/weather/date').then(({ data }) => {
      console.log("/api/weather/date", data);
      return data;
    });
  };

  const getOptimizationHistory = (imo) => {
    return axios.get('/api/optimizationresult/imo/' + imo + '/list').then(({ data }) => {
      console.log("/api/optimizationresult/imo/" + imo + "/list'", data);
      return data;
    });
  };

  
  const getOptimizationResult = (voyage_hash) => {
    return axios.get('/api/optimizationresult/voyage/' + voyage_hash).then(({ data }) => {
      console.log("/api/optimizationresult/voyage/" + voyage_hash, data);
      return data;
    });
  };

  
  const saveOptimizationResult = (voyage_hash, optimizationMode) => {
    let request = {    
      // "messageId": generateUUID(), 
      // "datetime": new Date().toUTCString(),
      // "imo": imo, 
      // "eventType": "OptimizeRoute", 
      // "payload": null    
      mode: optimizationMode
  }
    return axios.post('/api/optimizationresult/voyage/'+ voyage_hash + '/save', request).then(({ data }) => {
      console.log("/api/optimizationresult/voyage/"+ voyage_hash + "/save" , data);
      return data;
    });
  };

  
  const lastOptimized = () => {
    return store.state.routeOptimization.details;
  };





  return {
    lastOptimized,
    optimizeRoute,
    getLastLoadedRoute,
    getLastOptimized,
    cancelOptimizingRoute,
    getWeatherDate,
    getOptimizationHistory,
    getOptimizationResult,
    saveOptimizationResult
  };
};
