<template>
  <div class="optimized" v-if="!isLoading" >
    <div class="optimized-top" >
      <div>
        <a-row style="height: 70px;margin-top: 10px;">
          <a-col style="width: 50%;">
            <div class="header">
              <div class="header-heading">
                <router-link class="header-back" :to="{ name: 'Fleet', params: { imo: state.imo, tab: 'route-optimizer', filter: 'route-for-optimize'} }">
                  <img :src="icons.arrowLeft" alt="arrow" />
                </router-link>
                <div>
                  <a-typography-title :level="2">{{$t('fleet-page.optimizations history')}}</a-typography-title>
                </div>
              </div>
            </div>
          </a-col>
          <a-col style="width: 50%;">
            <div>
            </div>
          </a-col>
        </a-row>     
      </div>
    </div>
      <div class="table" >
      <a-table
        class="table-src-route"
        :columns="columns"
        :data-source="dataSource.list"
        :customRow="customRow"
        :scroll="{ x: '1000px', y: 'calc(100vh - 340px)' }"
        
        :pagination="false"
        bordered
      >
        <template #headerCell="{ column }">
          <template v-if="column.key === 'created'">
            <span class="table-head">{{ column.title }}</span>
          </template>
          <template v-if="column.key === 'name'">
            <span class="table-head">{{ column.title }}</span>
          </template>
          <template v-if="column.key === 'from_to'">
            <span class="table-head">{{ column.title }}</span>
          </template>
          <template v-if="column.key === 'optimization_rule'">
            <span class="table-head">{{ column.title }}</span>
          </template>
          <template v-if="column.key === 'ptd'">
            <span class="table-head">{{ column.title }}</span>
          </template>
          <template v-if="column.key === 'pta'">
            <span class="table-head">{{ column.title }}</span>
          </template>
          <template v-if="column.key === 'load'">
            <span class="table-head">{{ column.title }}</span>
          </template>
          <template v-if="column.key === 'time_at_sea'">
            <span class="table-head">{{ column.title }}</span>
          </template>
          <template v-if="column.key === 'fo_consumption'">
            <span class="table-head">{{ column.title }}</span>
          </template>
          <template v-if="column.key === 'cii'">
            <span class="table-head">{{ column.title }}</span>
          </template>
          <template v-if="column.key === 'bad_weather_time'">
            <span class="table-head">{{ column.title }}</span>
          </template>
        </template>

        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'created'">
            <a-typography-text type="primary" style="font-size: 12px" v-if="record.created_at">
              {{ date.formatUTC(record.created_at) + " UTC" }}                
            </a-typography-text>
          </template>

          <template v-if="column.dataIndex === 'name'">
            <a-typography-text type="primary" style="font-size: 12px" v-if="record.route_info?.name">
              {{ record.route_info.name }}                
            </a-typography-text>
          </template>

          <template v-if="column.dataIndex === 'from_to'">
            <a-typography-text type="primary" style="font-size: 12px" v-if="record.route_info?.arrival_port && record.route_info?.departure_port">              
              {{ record.route_info.arrival_port}} -> {{ record.route_info.departure_port }}
            </a-typography-text>
          </template>
          
          <template v-if="column.dataIndex === 'load'">
            <a-typography-text type="primary" style="font-size: 12px" v-if="record.route_info?.load_condition && record.route_info?.load_condition === 'LADEN'">
              {{$t('fleet-page.laden')}}
            </a-typography-text>
            <a-typography-text type="primary" style="font-size: 12px" v-if="record.route_info?.load_condition && record.route_info?.load_condition === 'BALLAST'">
              {{$t('fleet-page.ballast')}}
            </a-typography-text>
          </template>

          <template v-if="column.dataIndex === 'ptd'">          
            <div v-if="record.route_info.etd">
              <a-typography-text type="primary" style="font-size: 12px">
                {{ date.formatUTC(record.route_info.etd) + " UTC" }}                
              </a-typography-text>
            </div>
          </template>

          <template v-if="column.dataIndex === 'pta'">             
            <a-row>
              <a-col style="border-bottom: 1px solid #e4e6e9; width: 100%;" v-if="record.const_power?.eta">
                <a-typography-text type="primary" style="font-size: 12px">
                  {{ date.formatUTC(record.const_power.eta) + " UTC" }}                
                </a-typography-text>
              </a-col>
              <a-col style="border-bottom: 1px solid #e4e6e9; width: 100%;" v-if="record.const_rpm?.eta">
                <a-typography-text type="primary" style="font-size: 12px">
                  {{  date.formatUTC(record.const_rpm.eta) + " UTC" }}                
                </a-typography-text>                
              </a-col>
              <a-col v-if="record.const_speed?.eta">
                <a-typography-text type="primary" style="font-size: 12px">
                  {{  date.formatUTC(record.const_speed.eta) + " UTC" }}                
                </a-typography-text>                
              </a-col>
            </a-row>
          </template>

          <template v-if="column.dataIndex === 'optimization_rule'">              
            <a-row>
              <a-col style="border-bottom: 1px solid #e4e6e9; width: 100%;">
                <a-typography-text type="secondary" style="font-size: 12px">
                  {{$t('fleet-page.const power')}}
                </a-typography-text>
              </a-col>
              <a-col style="border-bottom: 1px solid #e4e6e9; width: 100%;">
                <a-typography-text type="secondary" style="font-size: 12px">
                  {{$t('fleet-page.const RPM')}}
                </a-typography-text>                
              </a-col>
              <a-col>
                <a-typography-text type="secondary" style="font-size: 12px">
                  {{$t('fleet-page.const speed')}}
                </a-typography-text>                
              </a-col>
            </a-row>
          </template>
          
          <template v-if="column.dataIndex === 'time_at_sea'">            
            <a-row>
              <a-col style="border-bottom: 1px solid #e4e6e9; width: 100%;" v-if="record.const_power?.duration">
                <a-typography-text type="primary" style="font-size: 12px">
                  {{ date.formatDateFromMinutes(record.const_power.duration)}}                
                </a-typography-text>                
              </a-col>
              <a-col style="border-bottom: 1px solid #e4e6e9; width: 100%;" v-if="record.const_rpm?.duration">
                <a-typography-text type="primary" style="font-size: 12px">
                  {{ date.formatDateFromMinutes(record.const_rpm.duration)}}                
                </a-typography-text>                
              </a-col>
              <a-col v-if="record.const_speed?.duration">
                <a-typography-text type="primary" style="font-size: 12px">
                  {{ date.formatDateFromMinutes(record.const_speed.duration)}}                
                </a-typography-text>                
              </a-col>
            </a-row>
          </template>
          
          <template v-if="column.dataIndex === 'fo_consumption'">           
            <a-row>
              <a-col style="border-bottom: 1px solid #e4e6e9; width: 100%;" v-if="record.const_power?.fuel_consumption">
                <a-typography-text type="primary" style="font-size: 12px">
                  {{ record.const_power.fuel_consumption}} {{$t('units.mt')}}
                </a-typography-text>                
              </a-col>
              <a-col style="border-bottom: 1px solid #e4e6e9; width: 100%;" v-if="record.const_rpm?.fuel_consumption">
                <a-typography-text type="primary" style="font-size: 12px">
                  {{ record.const_rpm.fuel_consumption}} {{$t('units.mt')}}
                </a-typography-text>                
              </a-col>
              <a-col v-if="record.const_speed?.fuel_consumption">
                <a-typography-text type="primary" style="font-size: 12px">
                  {{ record.const_speed.fuel_consumption}} {{$t('units.mt')}}
                </a-typography-text>                
              </a-col>
            </a-row>
          </template>
          
          <template v-if="column.dataIndex === 'cii'">       
            <a-row>
              <a-col style="border-bottom: 1px solid #e4e6e9; width: 100%;" v-if="record.const_power?.cii">
                <a-typography-text type="primary" style="font-size: 12px">
                  {{ record.const_power.cii}}                
                </a-typography-text>                
              </a-col>
              <a-col style="border-bottom: 1px solid #e4e6e9; width: 100%;" v-if="record.const_rpm?.CII || record.const_rpm?.cii">
                <a-typography-text type="primary" style="font-size: 12px">
                  {{ record.const_rpm?.CII ?? record.const_rpm?.cii}}                
                </a-typography-text>                
              </a-col>
              <a-col v-if="record.const_speed?.cii">
                <a-typography-text type="primary" style="font-size: 12px">
                  {{ record.const_speed.cii}}                
                </a-typography-text>                
              </a-col>
            </a-row>
          </template>

          <template v-if="column.dataIndex === 'bad_weather_time'">                  
            <a-row>
              <a-col style="border-bottom: 1px solid #e4e6e9; width: 100%;" v-if="record.const_power">
                <a-typography-text type="primary" style="font-size: 12px">
                  {{ record.const_power.in_bad_weather + ' %'}}                
                </a-typography-text>                
              </a-col>
              <a-col style="border-bottom: 1px solid #e4e6e9; width: 100%;" v-if="record.const_rpm">
                <a-typography-text type="primary" style="font-size: 12px">
                  {{ record.const_rpm.in_bad_weather + ' %'}}                
                </a-typography-text>                
              </a-col>
              <a-col v-if="record.const_speed">
                <a-typography-text type="primary" style="font-size: 12px">
                  {{ record.const_speed.in_bad_weather + ' %'}}                
                </a-typography-text>                
              </a-col>
            </a-row>
          </template>

          <!-- <template v-if="column.dataIndex === 'details'">
              <router-link :to="{ name: 'Fleet', params: { imo: route.params.imo, id: record.voyage_hash, tab: 'route-optimizer', filter: 'optimized-route'} }">          
                <a-typography-text type="secondary" style="font-size: 12px"> View details </a-typography-text>
              </router-link>
          </template> -->

        </template>
      </a-table>
    </div>
  </div>
  <a-skeleton active class="loading"  v-if="isLoading"  />
</template>

<script>
import {
  watchEffect,
  reactive,
  onMounted,
  defineComponent,
  ref,
} from "vue";

import { useRouter, useRoute } from "vue-router";
// import { DeleteOutlined } from "@ant-design/icons-vue";
// import { LoadingOutlined } from '@ant-design/icons-vue';
import { useI18n } from "vue-i18n";
import { useLoading } from "@/composables/useLoading";
import { date } from "@/helpers";
import { kFormatter } from "@/helpers";
import { icons } from "@/assets";
import  useRouteOptimization from "@/composables/useRouteOptimization";

export default defineComponent({
  name: "FleetOptimizedRouteHistory",

  // props: {

  // },

  setup() {
    console.log("FleetOptimizedRouteHistory");    

    const dataSource = reactive({ list: [] });
    const route = useRoute();
    const router = useRouter();
    console.log(route.params.imo);
    const loading = useLoading();
    const routeOptimization = useRouteOptimization();

    const state = reactive({
      imo: route.params.imo,
    });

    
    onMounted(() => {
      loading.start();

      routeOptimization.getOptimizationHistory(route.params.imo).then((response) => {
        dataSource.list = response.payload.items;
        loading.finish();
        console.log("response.payload", response.payload)
      })
    });


    // console.log("dataSource.list")
    // routeOptimization.getOptimizationHistory(route.params.imo).then((response) => {
    //   dataSource.list = response.payload.items;
    //   console.log("response.payload", response.payload)
    // })

    // console.log("dataSource.list", dataSource.list)


    const { t } = useI18n();

    watchEffect(() => {   

    });

    const columns = ref([
          {
            title: t('fleet-page.Created'),
            dataIndex: "created",
            key: "created",
            width: "12%",
          },
          {
            title: t('fleet-page.NAME'),
            dataIndex: "name",
            key: "name",
            width: "8%",
          },
          {
            title: t('fleet-page.From/To'),
            dataIndex: "from_to",
            key: "from_to",
            width: "10%",
          },
          {
            title: t('fleet-page.vessel condition'),
            dataIndex: "load",
            key: "load",
            width: "6%",
          },
          {
            title: t('fleet-page.PTD'),
            dataIndex: "ptd",
            key: "ptd",
            width: "11%",
          },
          {
            title: t('fleet-page.Opt rule'),
            dataIndex: "optimization_rule",
            key: "optimization_rule",
            width: "7%",
          },
          {
            title: t('fleet-page.PTA'),
            dataIndex: "pta",
            key: "pta",
            width: "11%",
          },
          {
            title: t('fleet-page.time at sea'),
            dataIndex: "time_at_sea",
            key: "time_at_sea",
            width: "10%",
          },
          {
            title: t('fleet-page.Anticipated Fo Consumption'),
            dataIndex: "fo_consumption",
            key: "fo_consumption",
            width: "10%",
          },
          {
            title: t('fleet-page.CII'),
            dataIndex: "cii",
            key: "cii",
            width: "5%",
          },
          {
            title: t('fleet-page.bad weather time'),
            dataIndex: "bad_weather_time",
            key: "bad_weather_time",
            width: "10%",
          },
    ]);

    const customRow = (record) => {
      return {
        onClick: () => {  
        return router.push({
                      name: "Fleet",
                      params: {
                        id: record.voyage_hash,
                        imo: route.params.imo,
                        tab: 'route-optimizer',
                        filter: "optimized-route",
              },
            });
        },
      };
    };

    console.log("data", dataSource.list)

    return {
      route,
      router,
      columns,
      dataSource,
      // rotate,
      // indicator,
      customRow,
      icons,
      kFormatter,     
      routeOptimization,
      state,
      date,
      isLoading: loading.active,
      // exportOptimized,
    };
  },

});
</script>

<style lang="less" scoped>
.ps {
  height: 100%;
}


.destination{
  background-color: #F5F7FA;
  //padding-left: 10%;
  margin-left: 2%;
  //margin-right: 60%;
  margin-top: 1%;
  // margin-bottom: 2%;
  height: 60px;
  width: 700px;
  //padding-top: 0.7%;
  border: @color-primary;
  border-width: 1px;
  border-style: solid;
}

.import-route-file {
  color: @color-black;
  border-color: @color-border;
  background-color: @color-value-bg;
  margin-left: 10%;
  width: 80%;

}
.table{
  padding: 0px 0px;
}
.export {
  color: @color-white;
  border-color: @color-border;
  background-color: @color-primary-light;
  width: 115px;
  height: 34px;
  margin-left: 24px;
  margin-top: 12px
}
.reset {
  color: @color-black;
  border-color: @color-border;
  background-color: @color-secondary;
  width: 115px;
  height: 34px;
  margin-left: 24px;
  margin-top: 12px
}

.header {
  //height: 8.4rem;
  display: flex;
  align-items: center;
  //justify-content: space-between;
  padding: 1.55rem 2.8rem 0.55rem 2.8rem;

  &-heading {
    display: flex;
    align-items: center;
    gap: 3.2rem;

    h2.ant-typography {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      font-size: 21px;
      color: @color-black;

      //
      //.ant-typography-secondary {
      //  font-size: 1.8rem;
      //  font-weight: 400;
      //}
    }
  }

  &-back {
    img {
      height: 1.6rem;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  &-actions {
    .export-report {
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}


.table {
  height: calc(100vh - 14.4rem);
  display: flex;

  &-head {
    text-transform: uppercase;
    text-align: left;
  }

  &-check {
    color: @color-active;
    display: flex;
    justify-content: center;
    opacity: 0.5;
  }

  &-action {
    display: flex;
    justify-content: flex-end;

    a {
      &:not(:hover) {
        color: @color-secondary;
      }
    }
  }

  .icon-arrow {
    display: flex;
    align-items: center;

    img {
      height: 1.2rem;
    }
  }
}

.optimized {
  width: 100%;
  //display: flex;

  &-top {
    width: 100%;
    height: 25%;
    display: grid;
    background-color: @color-white;    
  }
  
  &-bottom {
    width: 100%;
    height: 70%;
    //position: relative;
    //background: @color-border

  }
  
}


</style>
