<template>

  <div class="factor" v-if="route.params.filter == 'route-for-optimize'">
    <!-- <div>      
    </div> -->


    <div class="factor-left">

      <div class="import-file" v-if="!state.showTable">
        <div class="description">
            <a-typography-text>
              {{$t('fleet-page.select route to optimize')}}
            </a-typography-text>
          </div>
          <div>
              <a-button class="import-route-file" @click="handleLoadRoute" html-type="submit" style="width: 100%; height: 44px;" v-if="!state.route_api_loaded?.features">
                <!-- <img :src="icons.file" alt="file" style="margin-right: 5px;"/> -->                 
                <FileOutlined style="font-size: 15px; margin-right: 5px;"/>
                {{$t('fleet-page.import route')}}
              </a-button>
              <a-button class="import-route-file" @click="handleLoadRouteFromApi" html-type="submit" style="width: 73%; height: 44px;" v-if="state.route_api_loaded?.features">
                <CloudDownloadOutlined style="font-size: 15px; margin-right: 5px;"/> 
                {{ $t('fleet-page.import route api') }} "{{ state.routename }}"
              </a-button>
              <a-button class="import-route-file" @click="handleLoadRoute" html-type="submit" style="width: 27%; height: 44px;" v-if="state.route_api_loaded?.features">
                <!-- <img :src="icons.file" alt="file" style="margin-right: 5px;"/> -->                 
                <FileOutlined style="font-size: 15px; margin-right: 5px;"/>
                {{$t('fleet-page.import route file')}} 
              </a-button>
          </div>
      </div>

      <div class="imported-file-route" v-if="state.showTable">
        <a-typography-text type="secondary" style="margin-left: 20px; display: inline-block; width: 70px;">
          {{ $t('fleet-page.route:') }}
        </a-typography-text>
        <a-typography-text type="primary" style="font-weight: 600; display: inline-block; width: 320px;">
          {{ state.routename }}
        </a-typography-text>

<!--        <router-link class="header-back" >-->
          <img :src="icons.trash" alt="trash" @click="handleReset"/>
<!--        </router-link>-->
<!--        <DeleteOutlined class="delete-icon" style="margin-left: 12%" />-->
      </div>

      <div class="etd" style="margin-left: 40px;margin-bottom: 20px;">
        <a-typography-text type="secondary">
          ETD:
        </a-typography-text>
        <a-date-picker show-time style="margin-left: 20px; width: 210px; border-width: 1px;"  v-model:value="state.etd"/>


      </div>

      <div class="eta" style="margin-left: 40px;    margin-bottom: 20px;">
        <a-typography-text type="secondary">
          ETA:
        </a-typography-text>
        <a-date-picker show-time style="margin-left: 20px; width: 210px; border-width: 1px;" v-model:value="state.eta"/>
        <a-typography-text type="secondary" style="margin-left: 5px; margin-right: 5px; font-size: 17px;"> ± </a-typography-text>
        <a-input placeholder="0.5" style="    width: 50px;"  v-model:value="state.eta_tolerance"/>
        <a-typography-text type="secondary" style="margin-left: 5px;"> {{$t('units.hr')}} </a-typography-text>
      </div>


      <div style="    margin-left: 40px">
        <a-typography-text type="secondary">
          {{$t('fleet-page.draught')}}
        </a-typography-text>
        <a-typography-text type="secondary" style="margin-left: 40px;">
          {{$t('fleet-page.weather forecast starting from')}}
        </a-typography-text>
      </div>
      <div style="    margin-bottom: 20px;    margin-top: 5px;">
        <a-input placeholder="12.0" style="    width: 60px;    margin-left: 40px;"  v-model:value="state.draught"/>
        <a-typography-text type="secondary" style="margin-left: 5px;"> {{$t('units.m')}} </a-typography-text>
        <a-date-picker show-time style="margin-left: 20px; width: 200px; border-width: 1px;" v-model:value="state.weatherDate" disabled>
        </a-date-picker>
      </div>

      <div v-if="!state.showTable" style="margin-left: 40px;">
        <div style="margin-bottom: 5px;" v-if="state.optimizationStatus == 'completed' || state.optimizationStatus == 'pending'">        
          <router-link :to="{ name: 'Fleet', params: { imo: route.params.imo, tab: 'route-optimizer', filter: 'optimized-route'} }"> 
            <ReloadOutlined style="font-size: 25px;" v-if="state.optimizationStatus == 'completed'"/>
            <a-typography-text type="secondary" style="margin-left: 5px; vertical-align: text-bottom;" v-if="state.optimizationStatus == 'completed'"> {{$t('fleet-page.view previous optimization result')}} </a-typography-text>          
            <LoadingOutlined style="font-size: 25px;" v-if="state.optimizationStatus == 'pending'"/>
            <a-typography-text type="secondary" style="margin-left: 5px; vertical-align: text-bottom;" v-if="state.optimizationStatus == 'pending'"> {{$t('fleet-page.view previous optimization progress')}} </a-typography-text>
          </router-link>
        </div>
        <div>        
          <router-link :to="{ name: 'Fleet', params: { imo: route.params.imo, tab: 'route-optimizer', filter: 'optimized-route-history'} }"> 
            <FieldTimeOutlined style="font-size: 25px;"/>
            <a-typography-text type="secondary" style="margin-left: 5px; vertical-align: text-bottom;"> {{ $t('fleet-page.view optimization history') }} </a-typography-text>
          </router-link>
        </div>
      </div>

      <div v-if="state.showTable">
        <div class="point-count" style="    margin-left: 30px;    margin-bottom: 5px;" >
          <a-typography-text type="secondary" v-if="!isLoading">
            {{ state.wpcount}} {{$t('fleet-page.waypoints in original route:')}}
          </a-typography-text>
          <a-skeleton active class="loading" v-else />
        </div>

        <div class="table" v-if="!isLoading">
          <a-table style="font-size: 12px"
              class="table-src-route"
              :columns="columns"
              :scroll="{ x: '100%', y: 'calc(100vh - 590px)' }"
              :data-source="dataSource.list"
              :pagination="false"
              bordered
          >
            <template #headerCell="{ column }">
              <template v-if="column.key === 'id'">
                <span class="table-head">{{ column.title }}</span>
              </template>
              <template v-if="column.key === 'name'">
                <span class="table-head">{{ column.title }}</span>
              </template>
              <template v-if="column.key === 'coords'">
                <span class="table-head">{{ column.title }}</span>
              </template>
            </template>

              <template #bodyCell="{ column, record }">
                <template v-if="column.dataIndex === 'id'">
                  <a-typography-text type="secondary">
                    {{ record.value.id }}
                  </a-typography-text>
                </template>


                <template v-if="column.dataIndex === 'name'">
                  <a-typography-text v-if="record.value.name" type="primary">
                    {{ record.value.name }}
                  </a-typography-text>
                  <a-typography-text v-else type="secondary">
                    Undefined
                  </a-typography-text>
                </template>


                <template v-if="column.dataIndex === 'coords'">
                  <a-typography-text type="primary">
                    {{ kFormatter.formatCoordinatesLat(record.value.coords[1])}}
                  </a-typography-text>
                  <br/>
                  <a-typography-text type="primary">
                    {{ kFormatter.formatCoordinatesLon(record.value.coords[0])}}
                  </a-typography-text>
                </template>

              </template>
          </a-table>
        </div>
        <a-skeleton active class="loading" v-else />

        <div style="top: 40px; position: relative;">
          <a-button class="optimize" @click="handleOptimize" html-type="submit" style="width: 145px">
            {{$t('fleet-page.buttons.optimize')}}
          </a-button>

          <a-button class="reset" @click="handleReset" html-type="submit" style=" margin-left: 38%;">
            {{$t('fleet-page.buttons.reset')}}
          </a-button>
        </div>

      </div>
    </div>
    <div class="factor-right">
      <div class="factor-map">
        <div id="routemapcontainer" />
      </div>
    </div>
  </div>

  <div class="routeoptimized" v-if="route.params.filter == 'optimized-route'">
    <FleetOptimizedRoute
        :cancelPoling="false"
    />
  </div>
  
  <div class="routeoptimizedhistory" v-if="route.params.filter == 'optimized-route-history'">
    <FleetOptimizedRouteHistory/>
  </div>
</template>

<script>
import {
  watchEffect,
  reactive,
  onMounted,
  ref,
  nextTick,
  defineComponent,
} from "vue";

import dayjs from 'dayjs';

import { useI18n } from "vue-i18n";

import { icons } from "@/assets";
import { useRouter, useRoute } from "vue-router";
import { useLoading } from "@/composables/useLoading";
import { date, rtzConverter } from "@/helpers";
import { kFormatter } from "@/helpers";
import useRouteOptimization from "@/composables/useRouteOptimization";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import {
  ReloadOutlined,
  FieldTimeOutlined,
  LoadingOutlined,
  CloudDownloadOutlined,
  FileOutlined
} from "@ant-design/icons-vue";

import {
  FleetOptimizedRoute,
  FleetOptimizedRouteHistory
} from "./route";

export default defineComponent({
  name: "FleetRouteOptimizer",

  setup() {
    var utc = require("dayjs/plugin/utc");
    dayjs.extend(utc);
    const route = useRoute();
    const loading = useLoading();
    const router = useRouter();
    const routeOptimization = useRouteOptimization();
    console.log("route.params", route.params);


    const state = reactive({
      map: null,
      filter: route.params.filter || "route-for-optimize",
      wpcount: 0,
      showTable: false,
      route_geo_json: null,
      route_api_loaded: null,
      route_rtz_raw: null,      
      routename: null,
      optimizationStatus: null,
      eta: null,
      etd: null,
      etd_tolerance: null,
      draught: null,
      imo: route.params.imo,
      weatherDate: ref(dayjs.utc()),
      //'YYYY-MM-DDThh:mmTZD new Date().toISOString(), 'YYYY-MM-DDTHH:mm:ss.fffZ'
    });
    
    const { t } = useI18n();
    // routeOptimization.getOptimizationHistory(route.params.imo);
    // routeOptimization.getOptimizationResult("ff3bd537444452d5484e28777af337b41bf7ee00");


    routeOptimization.getWeatherDate().then((response) => {   
      state.weatherDate = ref(dayjs.utc(response.payload.date , 'YYYY-MM-DDTHH:mm:ss.fffZ'))   
      console.log("weatherDate response", response);
    })

    const handleOptimize = () => {
      console.log("handleOptimize", state);

      const payload = {
        etd: state.etd.utc(true).toJSON(),
        eta: state.eta.utc(true).toJSON(),
        eta_tolerance: Number(state.eta_tolerance),
        draught: Number(state.draught),
        imo: Number(state.imo),
        route_rtz: state.route_rtz_raw ?? null,
        route_geojson: state.route_rtz_raw ? null:  state.route_geo_json
      }


      routeOptimization.optimizeRoute(Number(state.imo), payload).then((response) => {

        console.log("root loaded",response);
      
        router.push({
                      name: "Fleet",
                      params: {
                        imo: route.params.imo,
                        tab: route.params.tab,
                        filter: "optimized-route",
              },
            });

      });
    };

    const dataSource = reactive({ list: [] });

    onMounted(() => {
      if(!state.route_geo_json && !state.route_rtz_raw){
        state.showTable = false;        
        loadLastOptimizedRouteStatus();
        loadLastLoadedRoute();
      }

    });

    const loadLastLoadedRoute = () => {
    routeOptimization.getLastLoadedRoute(route.params.imo).then((response => {
      console.log("lastLoadedRoute", response);
      if(response.payload?.features)
      {
        state.route_api_loaded = response.payload;
        state.routename = state.route_api_loaded?.properties.departure_port + ' ' + t('fleet-page.to') + ' ' + state.route_api_loaded?.properties.arrival_port;
      }
    }))
  }


    const loadLastOptimizedRouteStatus = () => {
      routeOptimization.getLastOptimized(route.params.imo).then((data) => {
        state.optimizationStatus = data.status;
        console.log("state.optimizationStatus", state.optimizationStatus)
      });   
    }



    const initialContentTab = async () => {

      await nextTick();
      const features = state.route_geo_json.features;
      const coordinates = features.map((item) => [item.geometry.coordinates[0] < 0 ? item.geometry.coordinates[0] + 360 : item.geometry.coordinates[0], item.geometry.coordinates[1]]);

      console.log(coordinates);

      let start = 1;
      const coordinates2 = features.map((item) => ref({
        id: start++,
        coords: item.geometry.coordinates,
        name: item.properties?.waypoint_name
      }));

      // if (!dataSource.list.length) {
        dataSource.list = coordinates2;
        state.wpcount = dataSource.list.length;
      // }

      const result = [];
      let lastElement = 0;
      let resultCount = [];

      let shift = 0.;
      let lon_previous = features[0].geometry.coordinates[0];
      
      for (let i = 0; i < features.length; i++) {
        const el = features[i].properties?.weight;

        let current = features[i].geometry.coordinates[0];
          let lon_current = current + shift;
          if (lon_current - lon_previous > 180) {
              lon_current -= 360;
              shift -= 360;
          } else if (lon_previous - lon_current > 180) {
              lon_current += 360;
              shift += 360;
          }
          lon_previous = lon_current;

        let coordinates3 = [lon_current ,features[i].geometry.coordinates[1]];

        if (lastElement === 0) {
          lastElement = el;
        } else if (
            (el > 4 && lastElement <= 4) ||
            (el <= 4 && lastElement > 4)
        ) {
          resultCount.push(coordinates3);

          result.push({
            type: lastElement > 4 ? "#F03945" : "#81D862",
            data: resultCount,
          });

          resultCount = [];
          lastElement = el;
        }

        resultCount.push(coordinates3);
      }

      if (resultCount.length) {
        result.push({
          type: lastElement > 4 ? "#F03945" : "#47BF2F",
          data: resultCount,
        });
      }

      // if (!state.map) {
        state.map = new mapboxgl.Map({
          container: "routemapcontainer",
          style: process.env.VUE_APP_MAPBOX_STYLE,
          center: coordinates[0],
          zoom: 1,
        });

        state.map.on("load", () => {
          state.map.addSource("points", {
            type: "geojson",
            lineMetrics: true,
            data: {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: coordinates,
              },
            },
          });

          state.map.addSource("directionPoints", {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: [
                  coordinates[0],
                  coordinates[coordinates.length - 1],
                ],
              },
            },
          });

          result.map((item, i) => {
            state.map.addLayer({
              id: `points-${i}`,
              type: "line",
              source: {
                type: "geojson",
                lineMetrics: true,
                data: {
                  type: "Feature",
                  geometry: {
                    type: "LineString",
                    coordinates: item.data,
                  },
                },
              },
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": item.type,
                "line-width": 2,
              },
            });
          });

          state.map.addLayer({
            id: "directionPoints",
            type: "circle",
            source: "directionPoints",
            paint: {
              "circle-color": "transparent",
              "circle-radius": 5,
              "circle-stroke-width": 2,
              "circle-stroke-color": "#47BF2F",
            },
          });
        });
      // }

      await nextTick();

      if (state.map) {
        state.map.resize();

        state.map.fitBounds(
            [coordinates[0], coordinates[coordinates.length - 1]],
            { padding: 50 }
        );

        state.map.resize();
      }
    };

    watchEffect(() => {
      // console.log("watchEffect")
      if(state.imo != route.params.imo){
        handleReset();
      }

      state.filter = route.params.filter || "route-for-optimize";
      
      if ((state.route_geo_json && state.route_geo_json.features || state.route_rtz_raw)) {
        initialContentTab();
        state.showTable = true;
      }
    });

    const handleLoadRoute = () => {

      var input = document.createElement('input');
      input.type = 'file';

      input.onchange = e => {

        // getting a hold of the file reference
        var file = e.target.files[0];

        // setting up the reader
        var reader = new FileReader();
        reader.readAsText(file,'UTF-8');

        // here we tell the reader what to do when it's done reading...
        reader.onload = readerEvent => {
          var content = readerEvent.target.result; // this is the content!
          // console.log("converted", converted)
          
          console.log("fileContent", content)

          if(file.name.includes(".rtz") || file.name.includes(".RTZ")){
            state.route_rtz_raw = content;
            rtzConverter.convertRtzRouteToGeoJson(content).then((result) => {    
              console.log("converted", result)

              // state.route_geo_json = JSON.parse(converted);
              state.route_geo_json = result;
              state.routename = result.properties?.routename ?? file.name;

              if (state.route_geo_json.features) {                
                state.imo = route.params.imo
                console.log("state", state)
                initialContentTab();
                state.showTable = true;
              }
            })
          }
          else {              
              state.route_geo_json = JSON.parse(content);
              state.routename = content.properties?.routename ?? file.name;

              if (state.route_geo_json.features) {
                if(state.draught == undefined){
                  state.draught = state.route_geo_json.properties?.draught;
                }
                else{
                  state.route_geo_json.properties.draught = state.draught;
                }

                if(state.eta == undefined){
                  state.eta = ref(dayjs.utc(state.route_geo_json.properties?.eta, 'YYYY-MM-DDTHH:mm:ss.fffZ')) ;
                }
                else{
                  state.route_geo_json.properties.eta = ref(dayjs.utc(state.eta.toISOString(), 'YYYY-MM-DDTHH:mm:ss.fffZ'));
                }

                if(state.eta_tolerance == undefined){
                  state.eta_tolerance = state.route_geo_json.properties?.eta_tolerance;
                }
                else{
                  state.route_geo_json.properties.eta_tolerance = state.eta_tolerance;
                }

                if(state.etd == undefined){
                  state.etd = ref(dayjs.utc(state.route_geo_json.properties?.etd, 'YYYY-MM-DDTHH:mm:ss.fffZ')) ;
                }
                else{
                  state.route_geo_json.properties.etd = ref(dayjs.utc(state.etd.toISOString(), 'YYYY-MM-DDTHH:mm:ss.fffZ'));
                }

                state.imo = route.params.imo
                console.log("state", state)
                initialContentTab();
                state.showTable = true;
              }
          }
        }
      }
      input.click();
    };

    const handleReset = () => {      
      state.showTable = false;
      state.optimizationStatus = null;
      state.route_geo_json = null;
      state.route_rtz_raw = null;
      state.eta = null;
      state.etd = null;
      state.etd_tolerance = null;
      state.draught = null;
      state.imo = route.params.imo;      
      loadLastOptimizedRouteStatus();
      loadLastLoadedRoute();
    };

    
    const handleLoadRouteFromApi = () => {
            
      if(state.route_api_loaded){

        state.route_geo_json = state.route_api_loaded;        
        state.routename = state.route_api_loaded?.properties.departure_port + ' ' + t('fleet-page.to') + ' ' + state.route_api_loaded?.properties.arrival_port;

        if (state.route_geo_json.features) {
          if(state.draught == null){
            state.draught = state.route_geo_json.properties?.draught;
          }
          else{
            state.route_geo_json.properties.draught = state.draught;
          }

          if(state.eta == null && state.route_geo_json.properties?.eta){
            state.eta = ref(dayjs.utc(state.route_geo_json.properties?.eta, 'YYYY-MM-DDTHH:mm:ss.fffZ')) ;
          }
          else if(state.eta){
            state.route_geo_json.properties.eta = ref(dayjs.utc(state.eta.toISOString(), 'YYYY-MM-DDTHH:mm:ss.fffZ'));
          }

          if(state.eta_tolerance == null){
            state.eta_tolerance = state.route_geo_json.properties?.eta_tolerance;
          }
          else{
            state.route_geo_json.properties.eta_tolerance = state.eta_tolerance;
          }

          if(state.etd == null && state.route_geo_json.properties?.etd){
            state.etd = ref(dayjs.utc(state.route_geo_json.properties?.etd, 'YYYY-MM-DDTHH:mm:ss.fffZ')) ;
          }
          else if (state.etd){
            state.route_geo_json.properties.etd = ref(dayjs.utc(state.etd.toISOString(), 'YYYY-MM-DDTHH:mm:ss.fffZ'));
          }

          state.imo = route.params.imo
          console.log("state", state)
          initialContentTab();
          state.showTable = true;
        }

      }
    };



    const columns = ref([
          {
            title: t('fleet-page.WPT'),
            dataIndex: "id",
            key: "id",
            width: "17%",
          },
          {
            title: t('fleet-page.NAME'),
            dataIndex: "name",
            key: "name",
            width: "53%",
          },
          {
            title: t('fleet-page.COORDINATES'),
            dataIndex: "coords",
            key: "coords",
            width: "30%",
          },
    ]);

    return {
      route,
      icons,
      kFormatter,
      // voyageDetails,
      routeOptimization,
      // voyages,
      state,
      dataSource,
      // list,
      date,
      columns,
      initialContentTab,
      isLoading: loading.active,
      handleLoadRoute,
      handleReset,
      handleOptimize,      
      loadLastOptimizedRouteStatus,
      loadLastLoadedRoute,
      handleLoadRouteFromApi,
      headers: {
        authorization: 'authorization-text',
      },
    };
  },

  components: {
    FleetOptimizedRoute,
    FleetOptimizedRouteHistory,
    ReloadOutlined,
    FieldTimeOutlined,
    LoadingOutlined,
    CloudDownloadOutlined,
    FileOutlined
    // DeleteOutlined,
  },
});
</script>

<style lang="less" scoped>
.ps {
  height: 100%;
}

.imported-file-route{
  background-color: #F5F7FA;
  //padding-left: 10%;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
  margin-bottom: 20px;
  height: 44px;
  width: 440px;
  padding-top: 10px;
  border: @color-primary;
  border-width: 1px;
  border-style: solid;
}



.import-file {
  margin-top: 3px;
  margin-left: 30px;
  margin-right: 20px;
  margin-bottom: 20px;
  // width: 80%;
}
.import-route-file {
  color: @color-black;
  border-color: @color-border;
  background-color: @color-value-bg;
}

.optimize {
  color: @color-white;
  border-color: @color-border;
  background-color: @color-primary-light;
  width: 115px;
  height: 34px;
  margin-left: 24px;
  margin-top: 12px
}

.reset {
  color: @color-black;
  border-color: @color-border;
  background-color: @color-secondary;
  width: 115px;
  height: 34px;
  margin-left: 24px;
  margin-top: 12px
}
.table {
  height: calc(100vh - 590px);
  width: 100%;
  display: flex;

  // &-src-route{
  //   // height: calc(100vh - 350px);
  // // display:block;
  //   font-size: 12px;
  // }
}


.description {
  font-size: 14px;
  font-weight: 400;
  //margin-left: 10%;
  // width: 80%;
  margin-bottom: 5px;
  //padding: 0 20px;
  // line-height: 18.2px;

  .ant-typography {
    color: @color-secondary;
  }
}
.routeoptimized{
  width: 100%;
  height: 100%;
  //display: flex;
}

.factor {
  width: 100%;
  display: flex;

  &-left {
    width: 500px;
    //position: relative;
    background: @color-white;

  }

  &-right {
    width:calc(100% - 500px);
    //position: relative;
    background: @color-border
  }

  &-map {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    #routemapcontainer {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

  }
}

</style>
